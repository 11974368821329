$font-color: #1c1c1c;

@mixin flexBox($ai: center, $jc: center, $fd: row) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
  flex-direction: $fd;
}

@mixin fonts(
  $ftst: normal,
  $ftwt: normal,
  $ftsz: 1rem,
  $lnht: 1rem,
  $ltsp: normal,
  $cl: $font-color
) {
  font-style: $ftst;
  font-weight: $ftwt;
  font-size: $ftsz;
  line-height: $lnht;
  letter-spacing: $ltsp;
  color: $cl;
}

@mixin sizes($wth: 100%, $ht: 100%) {
  width: $wth;
  height: $ht;
}
